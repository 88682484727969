'use client';

import { RedirectType } from 'next/dist/client/components/redirect';
import { redirect, usePathname, useRouter } from 'next/navigation';

import { ReactNode, useCallback, useEffect, useMemo } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import Cookies from 'js-cookie';

import {
  AUTH_USER_INVALIDATION_QUERY_KEY,
  removeAuthUserQueries,
  useCurrentUserQuery,
  useLoginTestUserMutation,
} from '@query';

import { DeserializedUser, WalletInterface } from '@api';

import { logout } from '@shared/api';
import { getQueryClient } from '@shared/common/providers/ReactQuery/QueryProviderSsr';
import { storageKeys } from '@shared/constants';
import { createSafeContext, useSafeContext } from '@shared/helpers/context';
import clearSavedConnectionDetails from '@shared/helpers/web3/clearSavedConnectionDetails';

type ContextValue = {
  isAuth: boolean;
  isLoading: boolean;
  logout: (callback?: () => void) => void;
  userEvmWallet?: WalletInterface;
} & (
  | {
      isAuth: true;
      user: DeserializedUser;
    }
  | {
      isAuth: false;
      user: undefined | null;
    }
);

const Context = createSafeContext<ContextValue>();

export const useAuth = () => useSafeContext(Context);

export const useAuthUser = () => {
  const { user, isLoading } = useAuth();
  const router = useRouter();

  useEffect(() => {
    if (!isLoading && !user) {
      router.push('/');
    }
  }, [user, router, isLoading]);

  return user;
};

/**
 * CATION: might trigger infinite auth that will prevent page from loading
 * USE ONLY ON PROTECTED ROUTES
 */
export const useSafeAuthUser = () => {
  const user = useAuthUser();

  if (!user) {
    // TODO: add login form opening
    redirect('/', RedirectType.replace);
  }

  return user;
};

// NOTE: not sure if it is the right place for this hook and if naming suits
export const useIsCurrentDeveloper = (developerId: string) => {
  const { user, isLoading } = useAuth();

  return useMemo(
    () => !isLoading && developerId === user?.attributes.developerNameId,
    [isLoading, user?.attributes?.developerNameId, developerId],
  );
};

interface AuthProviderProps {
  children?: ReactNode;
  onLogout?: () => void;
}

const TEST_USER_ID = 1;

export const useAuthUsingTestUser = () => {
  const { isPending: isLoading, mutateAsync } = useLoginTestUserMutation();
  const login = useCallback(() => mutateAsync({ id: TEST_USER_ID }), [mutateAsync]);

  return {
    isLoading,
    login,
  };
};

export const logoutClient = (callback?: () => void) => {
  if (!Cookies.get('Authorized')) {
    return;
  }

  const queryClient = getQueryClient();

  if (queryClient) {
    removeAuthUserQueries(queryClient);
  }

  logout();

  // we should clear cookies after we clear quires data, to prevent logoutClient function to be called twice
  // because we subscribe window.cookieStore on change event and call logoutClient again
  Cookies.remove('Authorized');
  Cookies.remove(storageKeys.LOGGED_INTO_MAGIC_BOOST);
  clearSavedConnectionDetails();

  callback?.();
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const pathname = usePathname();
  const queryClient = useQueryClient();
  const { data: user, isLoading } = useCurrentUserQuery({
    enabled: !!Cookies.get('Authorized'),
  });
  const isAuth = !!user;
  const value = useMemo(() => {
    return {
      isLoading,
      isAuth,
      user: isAuth ? user : undefined,
      userEvmWallet: user?.wallets.find((wallet) => wallet.attributes.network === 'EVM'),
      logout: logoutClient,
    } as ContextValue;
  }, [isAuth, user, isLoading]);

  // TODO: come up with better solution
  useEffect(() => {
    window.__logoutClient = logoutClient;
  }, []);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    // Invalidates user related queries
    // That will allow us to get fresh data with user related fields when user authorizes
    // Because some endpoints requires authorization key to get that data
    queryClient.invalidateQueries({
      queryKey: [AUTH_USER_INVALIDATION_QUERY_KEY],
    });
  }, [isAuth, queryClient]);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    if (!Cookies.get('Authorized')) {
      logoutClient();

      return;
    }

    const handleCookieStoreChange = (event: { deleted: { name: string }[] }) => {
      const deletedAuthorizedCookie = event.deleted.find(
        (deleted) => deleted.name === 'Authorized',
      );

      if (deletedAuthorizedCookie) {
        logoutClient();
      }
    };

    window?.cookieStore?.addEventListener('change', handleCookieStoreChange);

    return () => {
      window?.cookieStore?.removeEventListener('change', handleCookieStoreChange);
    };
  }, [isAuth, pathname]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export default AuthProvider;
