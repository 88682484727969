import { ComponentPropsWithoutRef, useId } from 'react';

// separate component to prevent id collisions
// Note: Keep this file and launchpad-logo.svg in uikit similar html markup
const LaunchpadLogo = (props: ComponentPropsWithoutRef<'svg'>) => {
  const id = useId();
  const gradientIds = Array.from({ length: 3 }, (_, i) => `${id}-${i}`);

  return (
    <svg viewBox="0 0 800 800" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M339.296 25.2508C372.965 -8.41695 427.553 -8.41694 461.222 25.2508L554.42 118.445C420.183 155.077 292.667 222.946 183.435 321.999C174.441 330.154 160.389 335.721 142.511 336.491C94.5179 338.556 46.8384 351.807 3.74756 376.303C7.61417 362.556 14.9558 349.58 25.7725 338.764L339.296 25.2508Z"
        fill={`url(#${gradientIds[0]})`}
      />
      <path
        d="M774.746 338.764L681.546 245.568C644.906 379.82 577.019 507.351 477.936 616.59C469.783 625.58 464.215 639.626 463.441 657.497C461.362 705.49 448.097 753.165 423.586 796.25C437.369 792.391 450.38 785.04 461.222 774.199L774.746 460.686C808.415 427.018 808.415 372.432 774.746 338.764Z"
        fill={`url(#${gradientIds[1]})`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.2554 458.742C10.9469 449.105 10.6694 429.355 24.833 421.026C61.8188 399.275 103.053 387.504 144.637 385.714C170.971 384.581 197.011 376.204 216.536 358.498C330.39 255.254 465.176 187.859 606.359 156.314C628.957 151.264 648.739 171.045 643.689 193.643C612.137 334.84 544.725 469.64 441.452 583.499C423.749 603.017 415.368 629.046 414.228 655.371C412.428 696.929 400.658 738.136 378.919 775.1C370.589 789.263 350.839 788.985 341.201 775.677L311.269 734.343C299.805 718.513 283.131 707.477 264.936 700.337C228.63 686.09 194.597 664.306 165.243 634.986L165.006 634.749C135.681 605.392 113.894 571.355 99.6462 535.043C92.5063 516.847 81.4698 500.171 65.6374 488.707L24.2554 458.742ZM437.114 294.772C456.657 284.666 480.492 288.368 496.049 303.924C511.611 319.486 515.309 343.331 505.192 362.875L489.476 393.234C470.377 430.129 420.913 437.8 391.535 408.423C362.15 379.039 369.834 329.562 406.748 310.474L437.114 294.772Z"
        fill={`url(#${gradientIds[2]})`}
      />
      <defs>
        <linearGradient
          id={gradientIds[0]}
          x1="600.935"
          y1="1.13458e-06"
          x2="395.309"
          y2="794.552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[1]}
          x1="600.935"
          y1="1.13458e-06"
          x2="395.309"
          y2="794.552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
        <linearGradient
          id={gradientIds[2]}
          x1="600.935"
          y1="1.13458e-06"
          x2="395.309"
          y2="794.552"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8066" />
          <stop offset="0.5" stopColor="#E666FF" />
          <stop offset="1" stopColor="#66B3FF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LaunchpadLogo;
