// icons with unique ids for resolve problem hide icon in mobile
// same what we have in uikit icons(THIS IS DUBL ICONS)

import LaunchpadLogo from './LaunchpadLogo';
import MembershipFree from './MembershipFree';
import MembershipPlus from './MembershipPlus';
import MembershipPremium from './MembershipPremium';
import MembershipPremiumPlus from './MembershipPremiumPlus';
import MsqLogo from './MsqLogo';

export {
  MembershipFree,
  MembershipPlus,
  MembershipPremium,
  MembershipPremiumPlus,
  MsqLogo,
  LaunchpadLogo,
};
